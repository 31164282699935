@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: "Inter", "Helvetica Neue", "Helvetica";
  background-color: #F7F8F8;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #F68B1F;
}
.clamphead{
  font-size:clamp(2rem, 5vw, 5rem) ;
}
.clamp1 {
  font-size: clamp(1.5rem, 5vw, 2.8rem);
}
.clamp2 {
  font-size: clamp(1.1rem, 5vw, 2rem);
}

.clamp3 {
  font-size: clamp(1.1rem, 4vw, 1.3rem);
}
.clamp4 {
  font-size: clamp(16px, 3vw, 1rem);
}
.clamp5 {
  font-size: clamp(10px, 1vw, 1rem);
}

.clamp2Half {
  font-size: clamp(1.1rem, 5vw, 1.5rem);
}

.clamp3Half {
  font-size: clamp(0.9rem, 3.5vw, 1.1rem);
}
.clamp4Half {
  font-size: clamp(0.7rem, 3vw, 0.9rem);
}

.head-h1{
  text-shadow: 1px 1px 2px #000000;
}


