.video-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  overflow: hidden;
}

video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content {
  position: relative;
  z-index: 3;
  color: white;
}
.hover-image {
  overflow: hidden;
  border-radius:6px;
  img {
    transition: all 0.3s linear;
    &:hover {
      transform: scale(1.1);
      z-index: 2;
    }
  }
}
